import React, { useEffect, useState } from 'react';
import WebForm from '../components/webform.view';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import { getQueryParameters } from '../utils/functions';

export default function WebformPage() {
  const [joinCode, setJoinCode] = useState(null);
  const [commId, setCommId] = useState(null);
  const [isURLValid, setIsURLValid] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const paramsToExclude = ['j', 'm'];
  const queryParams = getQueryParameters(location.search, paramsToExclude);
  const [isAnonymousUser, setIsAnonymousUser] = useState(false);

  const decodeBase64 = (data) => {
    return Buffer.from(data, 'base64').toString('utf8');
  };

  function generateRandomEmail() {
    const length = 10;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return `${result}@nairobihospital.ke`;
  }

  function updateURLWithEmail(email) {
    const paramsObject = Object.fromEntries(searchParams.entries());
    paramsObject['m'] = email;
    setSearchParams(paramsObject);
    setIsAnonymousUser(true);
  }

  useEffect(() => {
    const appURL = new URL(window.location.href).searchParams;

    let jCode = appURL.get('j');
    let commID = appURL.get('m');

    if (!commID || !jCode) {
      setIsURLValid(false);
    }

    if (
      commID === 'rnd' ||
      commID === 'TEMP_EMAIL' ||
      commID === 'nbhanonymous@nairobihospital.ke'
    ) {
      commID = generateRandomEmail();
      updateURLWithEmail(commID);
    }

    if (commID && jCode) {
      commID = commID.trim();
      jCode = jCode.trim();
      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\\./0-9]*$/;
      const base64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      const alphanumericRegex = /^[a-z0-9]+$/i;
      const isAnonymousUser = /[^@]+@temporary\.ajua\.com$/;

      if (isAnonymousUser.test(commID)) {
        setIsAnonymousUser(true);
      } else {
        setIsAnonymousUser(false);
      }

      if (base64Regex.test(commID) && !phoneRegex.test(commID)) {
        commID = decodeBase64(commID);
      }
      if (base64Regex.test(jCode)) {
        const decodedJoinCode = decodeBase64(jCode);
        if (decodedJoinCode.length > 3 && alphanumericRegex.test(decodedJoinCode))
          jCode = decodedJoinCode;
      }
      if (!commID.match(emailRegex) && !commID.match(phoneRegex)) {
        setIsURLValid(false);
      } else {
        setJoinCode(jCode);
        setCommId(commID);
        setIsURLValid(true);
      }
    }
  }, []);

  return (
    <div>
      {isURLValid ? (
        <WebForm
          joinCode={joinCode}
          commId={commId}
          userMetadata={queryParams}
          isAnonymousUser={isAnonymousUser}
        />
      ) : (
        <Navigate to="/404" />
      )}
    </div>
  );
}
