import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import WebForm from '../components/webform.view';
import './landing.styles.scss';
import Loader from 'react-spinners/BounceLoader';
import { getQueryParameters } from '../utils/functions';

const API_URL = process.env.REACT_APP_API_URL;

export default function LandingPage() {
  const [commId, setCommId] = useState();
  const [joinCode, setJoinCode] = useState(null);
  const [isUrlValid, setIsURLValid] = useState(true);
  const [accountLogo, setAccountLogo] = useState('');
  const [hasEmail, setHasEmail] = useState(true);
  const [isEmailOrPhone, setEmailOrPhone] = useState(false);
  const [hasFacebook, setHasFacebook] = useState(false);
  const [hasTwitter, setHasTwitter] = useState(false);
  const [hasInstagram, setHasInstagram] = useState(false);
  const [userMetadata, setUserMetadata] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedContactMethod, setSelectedContactMethod] = useState('email');

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    // Temporary Fix For Exco TT

    const code = params.get('j');

    const storedEmail = localStorage.getItem('p_j');

    if (!params.get('j')) {
      setIsURLValid(false);
    } else {
      setJoinCode(params.get('j'));
      setIsURLValid(true);

      const paramsToExclude = ['j', 'm'];
      const queryParams = getQueryParameters(location.search, paramsToExclude);

      setUserMetadata({ ...userMetadata, ...queryParams });

      if (code === 'XOTUDQHU' || code === 'QN0K1DZU' || code === '9X4QN7ED' || code === '3UNL8O')
        setCommId(storedEmail);

      axios
        .get(`${API_URL}/accounts/no-auth/web-form/${params.get('j')}`)
        .then((data) => {
          setAccountLogo(data.data.Data.logo);
          const surveyMetadata = data?.data?.Data?.surveyMetadata;

          try {
            if (surveyMetadata) {
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyInputType')
                  ?.value === 'PHONE'
              )
                setHasEmail(false);
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyInputType')
                  ?.value === 'PHONE_AND_EMAIL'
              )
                setEmailOrPhone(true);
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyPlatform')
                  ?.value === 'TWITTER'
              )
                setHasTwitter(true);
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyPlatform')
                  ?.value === 'INSTAGRAM'
              )
                setHasInstagram(true);
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyPlatform')
                  ?.value === 'FACEBOOK'
              )
                setHasFacebook(true);
            }
            setLoading(false);
          } catch (e) {
            console.log(e);
          }
        })
        .catch((error) => console.error("Couldn't get account data : ", error));
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    if (hasEmail) setCommId(formData.get('email'));
    else setCommId(formData.get('phone'));
    // Temporary Fix For Exco TT
    if (joinCode === '3VGJOIC5') {
      localStorage.setItem('p_j', formData.get('email'));
    }

    if (hasFacebook)
      setUserMetadata({ ...userMetadata, facebookUsername: formData.get('facebook') });
    if (hasTwitter) setUserMetadata({ ...userMetadata, twitterUsername: formData.get('twitter') });
    if (hasInstagram)
      setUserMetadata({ ...userMetadata, instagramUsername: formData.get('instagram') });
  };

  const handleOptionChange = (e) => {
    setSelectedContactMethod(e.target.value);
    if (e.target.value === 'email') {
      setHasEmail(true);
    } else {
      setHasEmail(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Ajua Webforms | Home</title>
      </Helmet>
      {loading && (
        <div
          style={{
            zIndex: '999999',
            background: 'white',
          }}
        >
          <div className="loader-container">
            <Loader
              // css={override}
              size={50}
              color="#2196f3"
              loading={loading}
            />
          </div>
        </div>
      )}
      {isUrlValid && !loading && (
        <>
          {commId ? (
            <WebForm joinCode={joinCode} commId={commId} userMetadata={userMetadata} />
          ) : (
            <div className="container landing-page-wrapper">
              <div className="customer-logo">
                <img src={accountLogo} className="customer-account-avatar" alt="account logo"></img>
              </div>
              <form onSubmit={onSubmit} style={{ maxWidth: '800px', margin: '0 auto' }}>
                <p className="fw-300">
                  {joinCode === 'CSWXUK28' ? (
                    <div>
                      <h4 className="text-center">CUSTOMER FEEDBACK / MREJESHO WA HUDUMA</h4>
                      <div>
                        Dear Customer, thank you for choosing KCB. As part of enhancing our
                        services, kindly participate in rating your experience with KCB. Your
                        feedback is valuable to us.
                      </div>
                      <div className="mt-1">
                        Mpendwa Mteja, asante kwa kuchagua KCB. Ili kuboresha huduma zetu tunakuomba
                        ushiriki katika kutupatia maoni.
                      </div>
                      <div>Please share contact information:</div>
                    </div>
                  ) : (
                    <>
                      To proceed with this survey, please provide us with your contact information.
                    </>
                  )}
                </p>

                {isEmailOrPhone && (
                  <div style={{ display: 'flex', gap: 20 }}>
                    <label style={{ fontWeight: 300 }}>
                      Email
                      <input
                        type="radio"
                        value="email"
                        checked={selectedContactMethod === 'email'}
                        onChange={handleOptionChange}
                      />
                    </label>

                    <label style={{ fontWeight: 300 }}>
                      Phone
                      <input
                        type="radio"
                        value="phone"
                        checked={selectedContactMethod === 'phone'}
                        onChange={handleOptionChange}
                      />
                    </label>
                  </div>
                )}
                <div className="row g-3 mt-3">
                  {hasEmail ? (
                    <div className="col">
                      <label htmlFor="ajWebformEmailInput" className="form-label mb-2 fw-300">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="ajWebformEmailInput"
                        name="email"
                        required
                        placeholder="juma@mail.com"
                      />
                    </div>
                  ) : (
                    <div className="col">
                      <label htmlFor="webFormPhoneInput" className="form-label mb-2 fw-300">
                        {joinCode === 'CSWXUK28' ? <>Phone No. / Namba ya Simu</> : <>Phone No.</>}
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="webFormPhoneInput"
                        name="phone"
                        required
                        placeholder="0712345678"
                        pattern="[0-9]{10}"
                      />
                    </div>
                  )}
                </div>
                <div className="row g-3 mt-3">
                  {hasFacebook && (
                    <div className="col">
                      <label htmlFor="webFormFacebook" className="form-label mb-2 fw-300">
                        Facebook username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="webFormFacebook"
                        name="facebook"
                        required
                        placeholder="jumaHassan"
                      />
                    </div>
                  )}
                  {hasTwitter && (
                    <div className="col">
                      <label htmlFor="webFormTwitter" className="form-label mb-2 fw-300">
                        Twitter Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="webFormTwitter"
                        name="twitter"
                        required
                        placeholder="jumaHassan"
                      />
                    </div>
                  )}
                  {hasInstagram && (
                    <div className="col">
                      <label htmlFor="webFormInstagram" className="form-label mb-2 fw-300">
                        Instagram username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="webFormInstagram"
                        name="instagram"
                        required
                        placeholder="jumaHassan"
                      />
                    </div>
                  )}
                </div>
                <div className="mt-1 mb-3">
                  <small className="small my-1" style={{ fontSize: 10 }}>
                    By providing your information, you agree to sharing your feedback guided by our{' '}
                    <a
                      href="https://ajua.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </small>
                </div>
                <button className="btn btn-primary kq-red mt-3" type="submit">
                  Proceed
                </button>
                <section className="powered-by-ajua">
                  <p className="small" style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Powered by </span>
                    <a href="https://ajua.com" target={'_blank'} rel="noopener noreferrer">
                      <img
                        src="/icons/icon-512x512.png"
                        className="ajua-logo-image"
                        alt="ajua logo"
                        style={{ height: '2rem' }}
                      />
                    </a>
                  </p>
                </section>
              </form>
            </div>
          )}
        </>
      )}
      {!isUrlValid && <Navigate to="/404" />}
    </>
  );
}
