import React from 'react';
import './singleSelect.sass';
// import PropTypes from 'prop-types';

/* Component for single select question types.
Takes in a question,
maps through the answer list and displays all possible answers */

const SingleSelect = ({ question, onChange, value }) => {
  function handleAnswer(event) {
    const index = event.target.value.split(',')[0];
    const text = event.target.value.split(',')[1];
    onChange({ value: index, text }, question.questionID, question.questionLevel);
  }

  //removes numbered list present in this question type
  if (question.questionType === 'INTRO_MESSAGE_MULTIPLE_CHOICE') {
    question.questionText = question.questionText.replace(/\d+:\s+\w+\n?/g, '');
  }

  return (
    <div className="single-select">
      <h4 className="range-score-question">
        <p className="question-text" dangerouslySetInnerHTML={{ __html: question.questionText }}>
          {/*{question?.required === true ? (*/}
          {/*  <span className="required-star">*</span>) : (<></>)}*/}
          {/*<strong>{question.questionText}</strong>*/}
        </p>
      </h4>
      <div className="answer-list">
        {question &&
          question.answerList &&
          question.answerList
            .slice(0, question.questionID === 682034 ? -1 : undefined)
            .map((option, index) => (
              <div key={`${question.questionID}${index}`} className="radio-btn">
                <input
                  type="radio"
                  id={`${question.questionID}${index}`}
                  value={[index + 1, option]}
                  onChange={handleAnswer}
                  checked={(index + 1).toString() === value}
                />
                <label className="answer" htmlFor={`${question.questionID}${index}`}>
                  {option}
                </label>
              </div>
            ))}
      </div>
    </div>
  );
};

// SingleSelect.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   question: PropTypes.objectOf(PropTypes.any).isRequired,
// };
export default SingleSelect;
